<template>
  <div class="el_contact">
    <div class="lxwm-banner">
      <div class="wrapper">
        <h2 class="cn">联系我们</h2>
        <h4 class="en">Get in touch with us</h4>
        <div class="line"></div>
      </div>
    </div>
    <div class="lxwm mrow">
      <div class="wrapper">
        <div class="lxwm-lxfs">
          <div class="mtit ">
            <h2 class="cn">联系方式</h2>
          </div>
          <div class="info">
            <div class="info-l">
              <div class="info-row">
                <div class="icon"><img src="../../../assets/images/icon-lxdh.png" /></div>
                <dl class="tel">
                  <dd>
                    <h4>招商热线：</h4>
                    <div class="txt">0311-66571206</div>
                  </dd>
                  <dd>
                    <h4>客服热线：</h4>
                    <div class="txt">0311-66571208</div>
                  </dd>
                </dl>
              </div>
              <div class="info-row">
                <div class="icon"><img src="../../../assets/images/icon-dzyx.png" /></div>
                <div class="desc">
                  <h4>联系邮箱：</h4>
                  <div class="txt">sjzrzcyy@163.com</div>
                </div>
              </div>
            </div>
            <div class="info-r">
              <div class="info-row">
                <div class="icon"><img src="../../../assets/images/icon-yqdz.png" /></div>
                <div class="desc">
                  <h4>园区地址：</h4>
                  <div class="txt">中国河北石家庄高新区裕华东路453号创新大厦</div>
                  <div class="qr">
                    <img src="../../../assets/images/qr01.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lxwm-map"
             id="map">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: 'el_contact',
    }
  },
  mounted () {
    var map = new BMap.Map('map');
    var point = new BMap.Point(114.662576, 38.044547);
    map.centerAndZoom(point, 18);

    var mapStyle = {
      features: ["road", "building", "water", "land"],//隐藏地图上的poi
      //style : "dark"  //设置地图风格为高端黑
    }
    map.setMapStyle(mapStyle);

    //启用滚轮放大缩小，默认禁用
    map.enableScrollWheelZoom();

    var marker = new BMap.Marker(point);  // 创建标注
    map.addOverlay(marker);              // 将标注添加到地图中

  },
  methods: {

  }
}
</script>
<style scoped lang="scss">
</style>
